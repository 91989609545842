<template>
  <BetTickerFlagCell
    v-if="columnKey === 'flag'"
    :bet="bet"
  />
  <BetTickerIdCell
    v-else-if="columnKey === 'betId'"
    :bet="bet"
  />
  <div
    v-else-if="columnKey === 'stake'"
    class="bet-ticker-cell"
  >
    <span
      :title="displayValue"
      v-html="displayValue"
    />
    <Tooltip
      v-if="bet.freeStake > 0"
      text="Bet has free stake, please check 'Free stake' column"
      left
    >
      <Chip>
        FREE
      </Chip>
    </Tooltip>
  </div>
  <div
    v-else
    class="bet-ticker-cell"
  >
    <span
      :class="(isParlayColumn || (bet.hasDifferentEvents && columnKey === 'eventName') || (multiCompetition && columnKey === 'competitionName')) && 'parlay'"
      :title="displayValue"
      v-html="displayValue"
    />
  </div>
</template>

<script>
import { computed } from 'vue';
import { useStore } from 'vuex';
import {
  includes, startCase, toLower, replace,
} from 'lodash';
import MarketMapper from '@/services/helpers/market-mapper';
import OddsConverter from '@/services/helpers/odds-converter';
import BetTickerFlagCell from './BetTickerFlagCell';
import BetTickerIdCell from './BetTickerIdCell';
import Tooltip from '@/components/common/Tooltip';
import Chip from '@/components/common/Chip';

export default {
  components: {
    BetTickerFlagCell,
    BetTickerIdCell,
    Tooltip,
    Chip,
  },
  props: {
    columnKey: {
      type: String,
      required: true,
    },
    bet: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const parlayColumns = ['selection', 'eventId'];

    const selectedOddFormat = computed(() => store.getters.selectedOddFormat?.name);

    const displayOdd = (price) => {
      const americanOdd = price?.originalFormattedValue;
      if (americanOdd && selectedOddFormat.value === 'american') return `${americanOdd.signIsPlus ? '+' : '-'}${americanOdd.value}`;
      if (selectedOddFormat.value === 'probability') {
        const probability = parseFloat((price.probability + price.bias) * 100).toFixed(1);
        return (probability % 1 > 0) ? `${probability}%` : `${Math.round(probability)}%`;
      }
      return OddsConverter.getMappedOddValue(price, selectedOddFormat.value);
    };

    const setTeamNames = (selectionLabel, homeTeam, awayTeam) => {
      let replacedLabel = startCase(toLower(selectionLabel));
      if (includes(replacedLabel, 'Home') && replacedLabel !== 'Home Run') {
        replacedLabel = replace(replacedLabel, /Home/g, homeTeam?.name);
      }
      if (includes(replacedLabel, 'Away')) {
        replacedLabel = replace(replacedLabel, /Away/g, awayTeam?.name);
      }

      return replacedLabel;
    };

    const selectionName = ({
      marketCode, selection, isUsaView, competitors, sport,
    }) => {
      if (marketCode === 'PLAYER_POINT_BANDS') {
        return MarketMapper.selectionName(selection, sport, isUsaView);
      }
      const selectionNameLabel = MarketMapper.selectionName(selection, sport, isUsaView);
      return setTeamNames(selectionNameLabel, competitors?.[0], competitors?.[1]);
    };

    const displayLine = ({ params, marketCode, selection }) => {
      const line = params.LINE;
      if (!line) return '';
      if (!includes(marketCode, 'HANDICAP')) return ` ${line}`;
      let lineValue = '';
      lineValue = line;
      if (selection.selectionType.selectionCode === 'HOME' && line > 0) {
        lineValue = ` +${line}`;
      }
      if (selection.selectionType.selectionCode === 'AWAY' && line < 0) {
        lineValue = ` +${Math.abs(line)}`;
      }
      if (selection.selectionType.selectionCode === 'AWAY' && line > 0) {
        lineValue = ` -${line}`;
      }
      return lineValue;
    };

    const isParlayColumn = computed(() => props.bet.multibet && includes(parlayColumns, props.columnKey));
    const uniqueCompetitions = computed(() => [...new Set(props.bet.betParts?.map((betPart) => betPart.competitionName))]);
    const multiCompetition = computed(() => uniqueCompetitions.value.length > 1);
    const displayValue = computed(() => {
      if (multiCompetition.value && props.columnKey === 'competitionName') {
        const { competitionName } = props.bet;
        return uniqueCompetitions.value.length > 1 ? uniqueCompetitions.value.length : competitionName;
      }
      if (isParlayColumn.value || (props.bet.hasDifferentEvents && props.columnKey === 'eventName')) {
        return props.bet.betPartsCount;
      }
      if (props.columnKey === 'market') {
        const marketDisplayConfiguration = store.getters['betTicker/marketDisplayConfigurationByIds']({
          sportId: props.bet.sportId, marketCode: props.bet.market, selections: props.bet.marketParams?.SELECTIONS,
        });
        if (!marketDisplayConfiguration) return props.bet.market;
        return MarketMapper.mapBetTickerMarketName({
          marketParams: props.bet.marketParams,
          marketSummary: props.bet.marketSummary,
          marketDisplayConfiguration,
          homeTeam: props.bet.competitors[0] || {},
          awayTeam: props.bet.competitors[1] || {},
        });
      }
      if (props.columnKey === 'selection') {
        return `${selectionName({
          marketCode: props.bet.market,
          selection: props.bet.selection,
          isUsaView: props.bet.isUsaView,
          competitors: props.bet.competitors,
          sport: props.bet.sport,
        })}${displayLine({ params: props.bet.marketParams, marketCode: props.bet.market, selection: props.bet.selection })}`;
      }
      if (props.columnKey === 'odds') {
        return displayOdd(props.bet.odds);
      }
      return props.bet[props.columnKey] || '-';
    });

    return {
      displayValue,
      isParlayColumn,
      multiCompetition,
    };
  },
};
</script>

<style lang="scss">
  .bet-ticker-cell {
    overflow: hidden;

    span {
      width: 100%;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &.parlay {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25px;
        height: 25px;
        border: 1px solid $gray500;
        border-radius: 50%;
      }
    }
  }

  .table-row .table-cell.table-cell--flag {
    padding-left: 0;
  }
  .table-row .table-cell.table-cell--stake {
    .bet-ticker-cell {
      overflow: visible;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0 8px;

      >.tooltip .chip{
        background-color: $white;
      }
    }
  }
</style>
